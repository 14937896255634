
import { InsuranceContractor } from '@/models/insurance_contractor';
import { useRepositories } from '@/repository/repositories';
import { defineComponent } from 'vue';
import { RouteLocationNormalized } from "vue-router"
import { hasLength } from "@/util/strings";
import { ElMessage } from 'element-plus';
import { validateStringLength } from "@/util/validate";

type DataType = {
  insuranceContractors: InsuranceContractor[];
  name?: string;
  kana?: string;
  phoneNumber?: string;
  storeName?: string;
  selectingPageSize: number;

  totalPage: number;
  currentPage: number;
  totalNum: number;
  pageSize: number;

  loading: boolean;
  searchFailedMessage?: string;

  showDetailDialog: boolean;
  showCancelDialog: boolean;

  selected?: InsuranceContractor;
  selectedCancel?: InsuranceContractor;
}

export default defineComponent({
  name: "admin-search",

  setup() {
    const repos = useRepositories();
    return {
      repos,
    }
  },

  data(): DataType {
    return {
      insuranceContractors: [],
      name: undefined,
      kana: undefined,
      phoneNumber: undefined,
      storeName: undefined,

      totalPage: 1,
      currentPage: 1,
      totalNum: 0,
      pageSize: 10,
      selectingPageSize: 10,

      loading: false,
      searchFailedMessage: "",

      showDetailDialog: false,
      selected: undefined,

      showCancelDialog: false,
      selectedCancel: undefined,
    }
  },

  computed: {
    searchable(): boolean {
      return hasLength(this.name) ||
        hasLength(this.kana) ||
        hasLength(this.phoneNumber) ||
        hasLength(this.storeName);
    },
    showSearchFailedMessage(): boolean {
      return !hasLength(this.searchFailedMessage);
    },
    validateMemoMessage(): string {
      const { message } = validateStringLength(this.selected?.memo ?? "", 0, 500, "メモ");
      return message;
    }
  },

  methods: {
    isValidMemo(): boolean {
      const res = validateStringLength(this.selected?.memo ?? "", 0, 500, "メモ");
      if (!res.valid) return false;
      return true;
    },

    onChangePageSize(v: number): void {
      if (v <= 0) {
        this.selectingPageSize = 10;
      }
    },
    onClickDetail({ row }: { row: InsuranceContractor }): void {
      this.showDetailDialog = true;
      this.selected = row;
      return;
    },

    onClickCancelConfirmation({ row }: { row: InsuranceContractor }): void {
      this.showCancelDialog = true;
      this.selectedCancel = row;
      return;
    },

    onClickBackCancel(): void {
      this.showCancelDialog = false;
      this.selectedCancel = undefined;
    },

    async onClickCancel(): Promise<void> {
      await this.repos.insuranceContractor.cancel(this.selectedCancel?.id ?? "");
      this.search(this.currentPage);
      this.showCancelDialog = false;
      ElMessage({
        message: 'キャンセルに成功しました',
        type: 'success',
      });
      return;
    },

    async onClickSearch(): Promise<void> {
      if (!this.searchable) {
        ElMessage({
          message: '検索条件を入力してください',
          type: "error",
        });
        return;
      }

      this.currentPage = 1;
      await this.search(this.currentPage);
      this.replaceAfterSearch(this.currentPage);
    },

    onClickUpdateMemo(): void {
      this.updateMemo();
    },

    async updateCurrentPage(v: number): Promise<void> {
      this.currentPage = v;
      await this.search(v);
      this.replaceAfterSearch(v);
    },

    rowClassName({ row }: {
      row: InsuranceContractor
    }): string {
      return row.canceled ? "canceled" : "";
    },

    pushForSearch(page: number): void {
      const query = {
        page,
      } as any;
      if (hasLength(this.name as string ?? "")) {
        query.name = this.name as string;
      }
      if (hasLength(this.kana as string ?? "")) {
        query.kana = this.kana as string;
      }
      if (hasLength(this.phoneNumber as string ?? "")) {
        query.phoneNumber = this.phoneNumber as string;
      }
      if (hasLength(this.storeName as string ?? "")) {
        query.storeName = this.storeName as string;
      }
      if (this.pageSize !== 0) {
        query.pageSize = this.pageSize;
      }
      this.$router.push({
        name: "admin-search",
        query,
      })
    },

    replaceAfterSearch(page: number): void {
      const query = {
        page,
      } as any;
      if (hasLength(this.name as string ?? "")) {
        query.name = this.name as string;
      }
      if (hasLength(this.kana as string ?? "")) {
        query.kana = this.kana as string;
      }
      if (hasLength(this.phoneNumber as string ?? "")) {
        query.phoneNumber = this.phoneNumber as string;
      }
      if (hasLength(this.storeName as string ?? "")) {
        query.storeName = this.storeName as string;
      }
      if (this.pageSize !== 0) {
        query.pageSize = this.selectingPageSize;
      }
      this.$router.replace({
        name: "admin-search",
        query,
      })
    },

    setDataFromQuery(route: RouteLocationNormalized): void {
      if (hasLength(route.query.name as string ?? "")) {
        this.name = route.query.name as string;
      }
      if (hasLength(route.query.kana as string ?? "")) {
        this.kana = route.query.kana as string;
      }
      if (hasLength(route.query.phoneNumber as string ?? "")) {
        this.phoneNumber = route.query.phoneNumber as string;
      }
      if (hasLength(route.query.storeName as string ?? "")) {
        this.storeName = route.query.storeName as string;
      }
      if (hasLength(route.query.page as string ?? "") && Number.isFinite(Number(route.query.page))) {
        this.currentPage = Number(route.query.page);
      }
      if (hasLength(route.query.pageSize as string ?? "") && Number.isFinite(Number(route.query.pageSize))) {
        this.pageSize = Number(route.query.pageSize);
        this.selectingPageSize = Number(route.query.pageSize);
      }
      if (hasLength(route.query.currentPage as string ?? "") && Number.isFinite(Number(route.query.currentPage))) {
        this.currentPage = Number(route.query.currentPage);
      }
    },

    async search(page?: number): Promise<void> {
      if (this.loading) return;
      this.loading = true;
      try {
        const res = await this.repos.insuranceContractor.search({
          name: this.name,
          kana: this.kana,
          phoneNumber: this.phoneNumber,
          storeName: this.storeName,
          pageSize: this.selectingPageSize,
          page,
        });

        this.insuranceContractors = res.list;
        this.totalPage = res.totalPage;
        this.totalNum = res.totalNum;
        this.currentPage = res.currentPage;
        this.pageSize = this.selectingPageSize;

        if (res.list.length === 0) {
          ElMessage({
            message: '条件に一致するものはありません。',
          });
        } else if (page === 1) {
          ElMessage({
            message: '検索に成功しました。',
            type: "success",
          });
        }

      } catch (e) {
        this.loading = false;
        ElMessage({
          message: '検索に失敗しました。通信環境を確認の上再度お試しください。',
          type: 'error',
        });
        console.error("failed to search insurance contractor", e)
        return;
      }
      this.loading = false;
    },

    async updateMemo(): Promise<void> {
      if (this.selected === undefined) return;

      const validateRes = this.isValidMemo();
      if (!validateRes) {
        throw new Error(`failed validate for update memo. selected: ${this.selected}`);
      }

      try {
        await this.repos.insuranceContractor.updateMemo(this.selected);
         ElMessage({
          message: 'メモ更新に成功しました。',
          type: "success",
        });
      } catch (e) {
        ElMessage({
          message: 'メモ更新に失敗しました。',
          type: "error",
        });
        throw new Error(`failed update memo. err: ${e}}`);
      }
    }
  },

  mounted(): void {
    this.setDataFromQuery(this.$route);
    if (this.searchable) {
      this.search(this.currentPage);
    }
  },
});
